<template>
          <span class="ml-50 text-body"> {{ reader }}</span>
</template>
<style>
.center {
    display: list-item;
        list-style: none;

}
</style>
<script>
import { BNavItemDropdown, BDropdownItem, BImg } from 'bootstrap-vue'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BImg,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      reader : localStorage.getItem('reader')
    }
  },
  methods : {
    changeCenter(center_id,name) {
      this.$store.commit('center/UPDATE_CENTER_ID', center_id)
      this.$store.commit('center/UPDATE_CENTER_NAME', name)
      this.$router.replace('/')
    }
  },
  // ! Need to move this computed property to comp function once we get to Vue 3
  computed: {
    availableCenter() {
      return this.userData.centers
    },
    currentCenter() {
      return this.$store.getters['center/currentCenterName']
    },
  },
  created() {
    var host = window.location.host
    console.log("host:"+host)
    if ( host == "valleyfit.gfs.hk" ) {
      location.href = "https://valleyfitstudio.gfs.hk";
    }
    if (this.currentCenter == '' ) {
      this.$store.commit('center/UPDATE_CENTER_ID', this.userData.default_center_id)
      this.$store.commit('center/UPDATE_CENTER_NAME', this.userData.default_center_name)
    }

    if (this.userData.token && this.$http.defaults.headers.Token === undefined) {
      this.$http.defaults.headers.Token = this.userData.token
    }
    //console.log(availableCenter)
  },
}
</script>

<style>

</style>
