<template>
  <b-nav-item-dropdown
    id="dropdown-grouped"
    variant="link"
    class="dropdown-language center"
    left
  >
    <template #button-content>
      <feather-icon
        icon="HomeIcon"
        size="17"
        style="margin-top:-5px;"
      /><span class="ml-50 text-body font-weight-bolder">{{ currentCenter }}</span>
    </template>
    <b-dropdown-item
      v-for="center in availableCenter"
      :key="center.id"
    >
      <span class="ml-50" @click="changeCenter(center.id,center.name)">{{ center.name }}</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
  
  
</template>
<style>
.center {
    display: list-item;
        list-style: none;

}
</style>
<script>
import { BNavItemDropdown, BDropdownItem, BImg } from 'bootstrap-vue'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BImg,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
    }
  },
  methods : {
    changeCenter(center_id,name) {
      this.$store.commit('center/UPDATE_CENTER_ID', center_id)
      this.$store.commit('center/UPDATE_CENTER_NAME', name)
      this.$router.replace('/')
    }
  },
  // ! Need to move this computed property to comp function once we get to Vue 3
  computed: {
    availableCenter() {
      return this.userData.centers
    },
    currentCenter() {
      return this.$store.getters['center/currentCenterName']
    },
  },
  created() {
    var host = window.location.host
    console.log("host:"+host)
    if ( host == "valleyfit.gfs.hk" ) {
      location.href = "https://valleyfitstudio.gfs.hk";
    }
    if (this.currentCenter == '' ) {
      this.$store.commit('center/UPDATE_CENTER_ID', this.userData.default_center_id)
      this.$store.commit('center/UPDATE_CENTER_NAME', this.userData.default_center_name)
    }

    if (this.userData.token && this.$http.defaults.headers.Token === undefined) {
      this.$http.defaults.headers.Token = this.userData.token
    }
    //console.log(availableCenter)
  },
}
</script>

<style>

</style>
